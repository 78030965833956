import React, { useEffect, useState } from 'react';
import Item from "./Item";

const uniq = (a) => {
    let seen = {};
    return a.filter(function (item) {
        return seen.hasOwnProperty(item?.slug) ? false : (seen[item?.slug] = true);
    });
};

const Filter = ({...args}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };
    const slugObj = [];

    const [filterTerm, setFilterTerm] = useState([]);
    const [isActiveItem, setIsActiveItem] = useState([]);

    const handleSetFilterTerms = (event) => {
        if (!filterTerm.includes(event)) {
            filterTerm.push(event);
        } else {
            let shift = filterTerm.filter(function (f) {
                return f !== event
            });
            clearState({clearItems: true});
            setFilterTerm(shift);
        }

        handleChange(event);
    }

    const handleChange = (event) => {
        let eventArray = isActiveItem.map((el) => {
            if (event === el.slug) {
                el.state = !el?.state;
            }
            return el;
        });
        setIsActiveItem(eventArray);
    };

    const clearState = ({clearItems}) => {
        setFilterTerm([]);
        if (!!clearItems) return false;

        let eventArray = isActiveItem.map((el) => {
            el.state = false;
            return el;
        });
        setIsActiveItem(eventArray);
    };

    const handleGetFilterTerms = (event) => {
        event.preventDefault();
        args.filterTerms(filterTerm);
        setOpen(!open);
    }

    useEffect(() => {
        const getFilterItems = () => {
            let getFilterValue = args.items.map((element) => {
                for (let slug in args.filtersObj) {
                    return slug.split('.').reduce((obj, index) => {
                        if (obj[index] !== null) {
                            return obj[index];
                        }
                    }, element);
                }
            }).reduce(function (a, b) {
                return a.concat(b);
            });

            return uniq(getFilterValue);
        };
        setIsActiveItem(getFilterItems());
    }, [args.items, args.filtersObj]);

    //TODO It needs refactoring & replace to getFilterItems method.
    args.items.map((element) => {
        for (let slug in args.filtersObj) {
            let filterValue = slug.split('.').reduce((obj, index) => {
                if (obj[index] !== null) {
                    return obj[index];
                }
            }, element);

            if (!slugObj[args.filtersObj[slug]] && filterValue !== undefined) {
                slugObj[args.filtersObj[slug]] = [filterValue];

                if (filterValue !== undefined && typeof filterValue === 'object') {
                    for (let objSlug of filterValue) {
                        slugObj[args.filtersObj[slug]] = [objSlug]
                    }
                }

            } else {
                if (filterValue !== undefined && !slugObj[args.filtersObj[slug]].includes(filterValue) && typeof filterValue === 'string') {
                    slugObj[args.filtersObj[slug]].push(filterValue)
                } else if (filterValue !== undefined && typeof filterValue === 'object') {

                    slugObj[args.filtersObj[slug]].map((el) => {
                        for (let objSlug of filterValue) {
                            if (el.slug !== objSlug.slug)
                                slugObj[args.filtersObj[slug]].push(objSlug)
                        }
                    });
                }
            }

            slugObj[args.filtersObj[slug]] = uniq(slugObj[args.filtersObj[slug]]);
        }
    });

    return (
        <div className={`tooltip-popup-wrapper black ${(open ? 'open-tooltip' : '')}`}>
            <button className={'open-filter possibilities-link mc-link mc-button-secondary'}
                    onClick={handleOpen}
                    aria-label='Filter'>Filters
            </button>
            <form className={'gray tooltip-popup scale-up-tl'}
                  onSubmit={handleGetFilterTerms}>
                <input type='reset' className={'clear black mc-button-secondary'} onClick={clearState}
                       aria-label='Clear all' value='Clear all'/>
                <div className={'tooltip-content flex-container-nowrap__sb'}>
                    {!!slugObj && Object.entries(slugObj).map((el, i) =>
                        <Item
                            data={isActiveItem}
                            key={i}
                            title={el[0]}
                            handleSetFilterTerms={handleSetFilterTerms}
                            isActiveItem={isActiveItem}
                        />
                    )}
                </div>
                <div className={'apply-wrapper'}>
                    <button className={'apply open-filter black possibilities-link mc-link mc-button-secondary'}
                            type='submit'
                            aria-label='Apply filters'>Apply filters
                    </button>
                </div>
            </form>
        </div>
    )
};

export default Filter;
