import React from "react";

export const WithoutResults = () => {
    return (
        <>
            <span>
                No results found ¯\_(ツ)_/¯
            </span>
        </>
    )
}
