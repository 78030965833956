import React, { useState } from "react";
import "../style.scss";

const SearchComponent = ({searchTerms}) => {
    const [searchTerm, setSerchTerm] = useState('');
    const [isActive, setActive] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        searchTerms(searchTerm);
        closeSearch();
    }

    const closeSearch = () => {
        setActive(!isActive);
        let form = document.querySelector('.search-form');
        form.reset();
    }
    
    return (
        <div className={`search-wrapper${(isActive ? ' open' : '')}`}>
            <div className={`search blog-search header-search flex-item`}>
                <div className="search-form-wrapper">
                <button 
                onClick={closeSearch}
                        className={'modal-video-close-btn'}
                        aria-label='Close'/>
                    <form className={'form search-form'}
                        id="blog-search-form"
                        role="search"
                        onSubmit={handleSubmit}
                    >
                        <div 
                            className={`input-wrapper`}
                            role="presentation">
                            <input
                                type="text"
                                name={'search'}
                                placeholder="Search"
                                id="blog-search-input"
                                autoComplete="off"
                                onClick={() => setActive(true)}
                                onChange={(event) => setSerchTerm(event.target.value)}
                            />
                        </div>
                        <label className={'label-container transition-link mc-button-secondary'}
                            htmlFor="blog-search-input">
                            <button
                                type={'submit'}
                                className={'button-image button-search'}
                                aria-label="Find"
                            />
                            <span className={'label input-label black'}>Find</span>
                        </label>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent