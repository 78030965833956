import React from "react";

const Item = ({slug, item, handleSetFilterTerms}) => {
    return (
        <li>
            <label
                htmlFor={(typeof slug == 'string' ? slug : slug.slug) + item}
                className={'accept-policy h6-title checkbox-holder'}
            >
                <input className={`input black filter-items`}
                       id={(typeof slug == 'string' ? slug : slug.slug) + item}
                       type={'checkbox'}
                       name={typeof slug == 'string' ? slug : slug.slug}
                       checked={!!slug?.state}
                    onChange={() => handleSetFilterTerms(typeof slug == 'string' ? slug : slug.slug)}
                       value={typeof slug == 'string' ? slug : slug.slug}
                />
                {typeof slug == 'string' ? <span>{slug}</span> : <span>{slug.name}</span>}
            </label>
        </li>
    )
}

export default Item
