import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
// import { navigate } from '@reach/router';
import Layout from '../components/layout';
import TitleLarge from "../components/title-large-content";
import EventsCalendarLists from "../components/events-calendar";
import TrackVisibility from 'react-on-screen';
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import prevNextPaginationButtons from "../helper/prevNextPaginationButtons";
import SearchComponent from "../components/search-component";
import { WithoutResults } from "../components/search/WithoutResults";
import Filter from "../components/Filter";
  
export default ({ data, pageContext }) => {
    const {
        customFields,
        calendarFields
    } = data;
    const { seo, additionalMetaTag, title, eventsCalendarCustomFields } = customFields;

    const { eventsTitleLargeContent } = eventsCalendarCustomFields;
    // Set metadata
    const seoData = { ...{ seo }, additionalMetaTag, title };
    //Pagination
    const prevButton = 'case-studies-previous';
    const nextButton = 'case-studies-next';
    const keyPagination = process.env.KEY_PAGINATION_EVENTS_CALENDAR;
    const [paginateButtons, setPaginateButtons] = useState(false);
    const [nodes, setNodes] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);
    const [defautItems, setDefautItems] = useState(null);
    const filtersObj = {'calendarCategories.nodes': 'Category',
                        // 'calendarFields.dateStart': 'By month start', 
                        // 'calendarFields.dateEnd': 'By month end', 
                        // 'calendarFields.price': 'Price'
                    };

    useEffect(() => {
        const endOffset = itemOffset + pageContext.perPage;
        const prevButtonEl = document.getElementsByClassName(prevButton);
        const nextButtonEl = document.getElementsByClassName(nextButton);
        const paginateButtonsEl = { prevButtonEl, nextButtonEl };
        const defElements = defautItems !== null ? defautItems : eventNodes;

        setPaginateButtons(paginateButtonsEl);
        setNodes(defElements.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(defElements.length / pageContext.perPage));

    }, [prevButton, nextButton, itemOffset, pageContext.perPage]);

    const handlePageClick = (event) => {
        const page = event.selected + 1;
        const path = page === 1 ? `/${keyPagination}/` : `/${keyPagination}/${page}/`;
        const newOffset = (event.selected * 8) % eventNodes.length;

        setPageCurrent(page);
        // navigate(path);
        setItemOffset(defautItems ? (event.selected * 8) % defautItems.length : newOffset);
        window.scrollTo(0, 0);
    };

    const handleTerm = (terms) => {
        let searchFilter = eventNodes.filter((val) => {
            if (terms == "") {
                return val;
            } else if (val.title.toLowerCase().includes(terms.toString().toLowerCase())) {
                return val;
            }
        });

        const endOffset = itemOffset + pageContext.perPage;
        setDefautItems(searchFilter);
        setNodes(searchFilter.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(searchFilter.length / pageContext.perPage));
        setItemOffset(0);
        setPageCurrent(1);
        window.scrollTo(0, 0);
    }

    const handleFilterTerm = (terms) => {
        let filteredArray = [];
        
        if (terms.length > 0) {
            terms.map((el) => {

                let filterTerms = eventNodes.filter((val) => {
                    let elReturn = null;

                    if (el == "") {
                        elReturn = val;
                    } else {
                        for(let slug in filtersObj) {
                            let q = slug.split('.');

                            if(val[q[0]][q[1]] !== null) {
                                if (typeof val[q[0]][q[1]] !== 'object') {
                                    if(val[q[0]][q[1]].includes(el))
                                    elReturn = val;
                                } else {
                                    val[q[0]][q[1]].map((slug) => {
                                        if(slug.slug.includes(el)) {
                                            elReturn = val;
                                        }
                                    })
                                }
                            }
                        }
                    }

                    return elReturn !== null ? elReturn : false;
                });
    
                for(let element of filterTerms) {
                    filteredArray.push(element);
                }
            });

            filteredArray.sort((a, b) => (a.calendarFields.dateStart > b.calendarFields.dateStart) ? 1 : -1);

            let uniqueChars = [];
            filteredArray.forEach((c) => {
                if (!uniqueChars.includes(c)) {
                    uniqueChars.push(c);
                }
            });
            
            const endOffset = itemOffset + pageContext.perPage;
            setDefautItems(uniqueChars);
            setNodes(uniqueChars.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(uniqueChars.length / pageContext.perPage));
            setItemOffset(0);
            setPageCurrent(1);
            window.scrollTo(0, 0);
        } else {
            const endOffset = itemOffset + pageContext.perPage;
            setDefautItems(eventNodes);
            setNodes(eventNodes.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(eventNodes.length / pageContext.perPage));
            setItemOffset(0);
            setPageCurrent(1);
            window.scrollTo(0, 0);
        }
        
    }

    const eventNodes = calendarFields.nodes.reduce((r, a) => {
        const fullDateStart = new Date(a.calendarFields.dateStart).getTime(),
            fullDateEnd = a.calendarFields.dateEnd ? new Date(a.calendarFields.dateEnd).getTime() : false,
            today = new Date(),
            yesterday = today.setDate(today.getDate() - 1);

            if (fullDateEnd) {
                r = fullDateEnd >= yesterday ? [...r || [], a] : r;
            } else {
                r = fullDateStart >= yesterday ? [...r || [], a] : r;
            }

        return r;
    }, []);

    return (
        <Layout
            seoData={seoData}>
            <Helmet
                bodyAttributes={{
                    class: 'case-study-wrapper events-calendar-wrapper'
                }}
            />
            <div className={'page-content case-studies events-calendar inner-pages'}>
                <TitleLarge
                    data={eventsTitleLargeContent}/>

                <div className={'row-holder'}>
                    <Filter items={eventNodes}
                            filtersObj={filtersObj} 
                            filterTerms={handleFilterTerm} />

                    <SearchComponent searchTerms={handleTerm}/>
                </div>

                {nodes !== null && nodes.length ? (
                    <TrackVisibility once offset={2000}>
                        <EventsCalendarLists data={{nodes}}
                                            showButton={false}
                                            showNumberItems={false}/>
                    </TrackVisibility>
                ) : 
                <div className={"without-results"}>
                    <WithoutResults />
                </div>
                }

                {pageCount && pageCount > 1 ? (
                    <div className={'pagination-wrapper'}>
                        <ReactPaginate
                            previousClassName={prevButton}
                            nextClassName={nextButton}
                            previousLabel={
                                pageCurrent !== 1 && <span className={'pager-arrow arrow-left'}/>
                            }
                            nextLabel={
                                pageCount !== pageCurrent && (
                                    <span className={'pager-arrow arrow-right'}/>
                                )
                            }
                            onPageChange={handlePageClick}
                            disableInitialCallback
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            containerClassName={"pager blog-pager flex-justify-sb pager-list flex-container"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            pageClassName={"pager-item flex-center"}
                            initialPage={pageCurrent - 1}
                            hrefBuilder={(page) => {
                                prevNextPaginationButtons(paginateButtons);
                                return `/${keyPagination}/${page}/`;
                            }}
                        />
                    </div>
                ): ''}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query EventsCalendarQuery {
        calendarFields: allWpCalendarEvent(
            sort: { fields: calendarFields___dateStart, order: ASC }
        ) {
            nodes {
                date(formatString: "MMMM YY")
                uri
                title
                content
                calendarFields {
                    url
                    googleCalendarUrl
                    dateStart
                    dateEnd
                    price
                    timeEnd
                    timeStart
                    fieldGroupName
                  }
                  databaseId
                  featuredImage {
                    node {
                      altText
                      mediaItemUrl
                    }
                  }
                  calendarCategories {
                    nodes {
                      name
                      slug
                    }
                  }
            }
        }

        customFields: wpPage(slug: {eq: "events-calendar"}) {
            slug
            title
            eventsCalendarCustomFields {
                eventsTitleLargeContent {
                    button
                    fieldGroupName
                    title
                    link {
                        fieldGroupName
                        text
                        url
                    }
                    backgroundColor
                    textColor
                    buttonSize
                }
            }
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage{
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }
    }`
