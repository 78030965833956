import React, { useEffect, useRef, useState } from "react"
import sanitizeHtml from 'sanitize-html';
import { OptionList } from "./options-list";
import sanitizeOptions from "../../helper/sanitizeOptions";
import * as constants from "../../helper/constants";

const TILT_MAX_ANGLE_X = 13;
const TILT_MAX_ANGLE_Y = 12;
const AUTORESET_ON_LEAVE = true;

export const Item = ({ setVisible, showNumberItems, i, page, startAnimation }) => {
    const sanitizeOpt = sanitizeOptions();
    let width = null;
    let height = null;
    let left = null;
    let top = null;
    let clientPosition = {
        x: 0,
        y: 0,
        xPercentage: 0,
        yPercentage: 0,
    };
    let tiltAngleX = 0;
    let tiltAngleY = 0;
    let updateAnimationId = null;
    let transitionTimeoutId = null;
    const wrapperEl = useRef(null);
    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        setSizeAndPosition();
        window.addEventListener('deviceorientation', onMove);
        (window.innerWidth < constants.MOBILE_WIDTH) ? setWindowSize(false) : setWindowSize(true);

        return window.addEventListener('deviceorientation', onMove);
    }, []);

    const setSizeAndPosition = () => {
        if (wrapperEl?.current) {
            const rect = wrapperEl.current.getBoundingClientRect();
            width = wrapperEl.current.offsetWidth;
            height = wrapperEl.current.offsetHeight;
            left = rect.left;
            top = rect.top;
        }
    };

    const onEnter = () => {
        if (!wrapperEl) {
            return;
        }

        wrapperEl.current.style.willChange = 'transform';
        setTransition();
    };

    const onMove = (event) => {
        if (!wrapperEl) {
            return;
        }
        if (updateAnimationId) {
            cancelAnimationFrame(updateAnimationId);
        }
        setSizeAndPosition();
        processInput(event);
        update(event.type);
        updateAnimationId = requestAnimationFrame(renderFrame);
    };

    const onLeave = () => {
        setTransition();

        const autoResetEvent = new CustomEvent('autoreset');
        onMove(autoResetEvent);
    };

    const processInput = (event) => {
        switch (event.type) {
            case 'mousemove':
                clientPosition.x = event.clientX;
                clientPosition.y = event.clientY;
                break;
            case 'touchmove':
                clientPosition.x = event.touches[0].pageX;
                clientPosition.y = event.touches[0].pageY;
                break;
            case 'deviceorientation':
                processInputDeviceOrientation(event);
                break;
            case 'autoreset':
                if (AUTORESET_ON_LEAVE) {
                    tiltAngleX = 0;
                    tiltAngleY = 0;
                }
                break;
            default:
                tiltAngleX = 0;
                tiltAngleY = 0;
        }
    };

    const processInputDeviceOrientation = (event) => {
        if (!event.gamma || !event.beta) {
            return;
        }

        const angleX = event.beta; // motion of the device around the x axis in degree in the range:[-180,180]
        const angleY = event.gamma; // motion of the device around the y axis in degree in the range:[-90,90]

        clientPosition.xPercentage = (angleX / TILT_MAX_ANGLE_X) * 100;
        clientPosition.yPercentage = (angleY / TILT_MAX_ANGLE_Y) * 100;
    };

    const update = (eventType) => {
        if (eventType === 'autoreset') {
            return;
        }

        if (eventType !== 'deviceorientation') {
            const { x, y } = clientPosition;
            clientPosition.xPercentage = ((y - top) / height) * 200 - 100;
            clientPosition.yPercentage = ((x - left) / width) * 200 - 100;
        }

        clientPosition.xPercentage = Math.min(Math.max(clientPosition.xPercentage, -100), 100);
        clientPosition.yPercentage = Math.min(Math.max(clientPosition.yPercentage, -100), 100);

        tiltAngleX = (clientPosition.xPercentage * TILT_MAX_ANGLE_X) / 100;
        tiltAngleY = -((clientPosition.yPercentage * TILT_MAX_ANGLE_Y) / 100);
    };

    const renderFrame = () => {
        if (wrapperEl.current) {
            wrapperEl.current.style.transform = `perspective(1000px) rotateX(${tiltAngleX}deg) rotateY(${tiltAngleY}deg) scale3d(1.26,1.2,1.2)`;
        }
    };

    const setTransition = () => {
        const duration = 1800;
        wrapperEl.current.style.transition = `all ${duration}ms cubic-bezier(.03,.98,.52,.99)`;

        transitionTimeoutId = setTimeout(() => {
            if (wrapperEl.current) {
                wrapperEl.current.style.transition = '';
            }
        }, duration);
    };

    const redirect = (url) => {
        window.location.href = url;
    };

    const dateFormater = (date) => {
        const dateStart = new Date(page.calendarFields.dateStart);

            date = dateStart.toLocaleString('en-US', {day: '2-digit', month: 'long'});

            if (dateStart && page.calendarFields.dateEnd) {
                const dateEnd = new Date(page.calendarFields.dateEnd);

                if (dateStart.getMonth() === dateEnd.getMonth()) {
                    date += '-' + dateEnd.getDate();
                } else {
                    date += ' - ' + dateEnd.toLocaleString('en-US', {day: '2-digit', month: 'long'});
                }
            }

        return date;      
    };

    const timeFormater = (time) => {
        let dateWithouthSecond = time.split(':');

        return dateWithouthSecond[0] + ':' + dateWithouthSecond[1];
    };

    return (
        <>
            <div
                onMouseEnter={onEnter}
                onMouseMove={onMove}
                onMouseLeave={onLeave}
                onTouchMove={onMove}
                onTouchStart={onEnter}
                onTouchEnd={onLeave}
                className={'case-study-item events-calendar-item item visible'}
                style={(setVisible(showNumberItems, i))}
                data-animate="animate-container"
                key={page.id}
                duration={1}
                hex="#ed5d2b"
                onClick={() => {redirect(page.calendarFields.url)}}
            >
                {(i < 1) && (
                    <div
                        className={'case-study-separator events-calendar-separator top'}
                        style={{
                            width: startAnimation ? '100%' : '0',
                            transition: 'all 1s ease'
                        }}
                    />
                )}
                <div
                    className={'case-study-separator events-calendar-separator bottom'}
                    style={{
                        width: startAnimation ? '100%' : '0',
                        transition: 'all 1s ease'
                    }}
                />
                <div
                    className={'case-study-link events-calendar-link flex-container-nowrap__sb'}>
                    <div className={'flex-item case-study-title events-calendar-title'}>
                        {!!page?.calendarCategories?.nodes.length && (
                            <div className={'paragraph-secondary minor-title black'}
                                data-animated-group="content">
                                <OptionList data={page.calendarCategories}
                                                    className={'black'}
                                                    classNameUl={'regions-list'}
                                                    classNameli={'regions-item'}/>
                            </div>
                        )}
                        <h4 className={'h4-title major-title black'}
                            data-animated-group="content">
                            {page.title}
                        </h4>
                    </div>
                    <div className={'flex-item case-study-tags'}>
                        {!!page?.calendarFields.dateStart && (
                            <div className={'tag-item region'}
                                data-animated-group="content">
                                    <span className={'paragraph-secondary black'}>Date:</span>
                                    <span className="h5-title minor-title black">{dateFormater()}</span>
                                    {!!page?.calendarFields.timeStart && (
                                        <div className={'time-label'}>
                                            <span className={'paragraph-secondary black'}>Time:</span>
                                            <span className="h5-title minor-title black">{timeFormater(page.calendarFields.timeStart)}</span>
                                            {!!page?.calendarFields.timeEnd && (
                                                <span className="h5-title minor-title black">{timeFormater(page.calendarFields.timeEnd)}</span>
                                            )}
                                        </div>
                                    )}
                            </div>
                        )}
                        {!!page?.calendarFields?.price && (
                            <div className={'tag-item industry'}
                                data-animated-group="content">
                                    <span className={'paragraph-secondary black'}>
                                        Price:
                                    </span>
                                    <span className="h5-title minor-title black">{page.calendarFields.price}</span>
                            </div>
                        )}
                    </div>

                    <div className={'flex-item case-study-description events-calendar-description paragraph-primary'}
                        data-animated-group="content">
                        {page?.content && (
                            <div className="black" dangerouslySetInnerHTML={{ __html: sanitizeHtml(page.content) }}/>
                        )}
                        {page?.calendarFields?.googleCalendarUrl && (
                            <a href={page.calendarFields.googleCalendarUrl} onClick={(e) => {e.stopPropagation()}} target="_blank" className="orange-text button-link">Add to Google Calendar</a>
                        )}
                    </div>

                    <div className={'case-study-image events-calendar-image absolute full-width'}>
                        <div
                            ref={wrapperEl}
                        >
                            
                            {page?.featuredImage?.node?.mediaItemUrl && (
                                <img src={page.featuredImage.node.mediaItemUrl}
                                    alt={page.featuredImage.node.altText}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}