import React, { useState } from "react";
import ItemElement from "./ItemElement";

const Item = ({data, title, handleSetFilterTerms}) => {
    const [isActive, setActive] = useState(true);
    const triggerHandler = () => {
        setActive(!isActive);
    };

    return (
        <div className={`filter-result-wrapper ${(isActive ? 'active' : '')}`}>
            <div className={'filter-title-wrapper'}
                 onClick={triggerHandler}
                 role={'button'}
            >
                <span className={'h5-title'}>{title}</span>
                <button className={'filter-slide'}>filter-slide</button>
            </div>
            <ul
                className={`filter-result-items`}>
                {data.map((slug, i) =>
                    <ItemElement
                        slug={slug}
                        key={i}
                        item={i}
                        handleSetFilterTerms={handleSetFilterTerms}
                    />
                )}
            </ul>
        </div>
    )
}

export default Item
