import React, {useState, useEffect} from "react";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import * as constants from "../../helper/constants";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";

const TitleBlock = ({isVisible, titleH5, titleH2, link}) => {
    const [startAnimation, setStartAnimation] = useState(false);
    const [windowSize, setWindowSize] = useState(false);
    const sanitizeOpt = sanitizeOptions();

    useEffect(() => {
        setWindowSize(window.innerWidth < constants.MOBILE_WIDTH);
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if (setIsVisible) {
            setStartAnimation(true);
        }

    }, [isVisible]);

    return (
        <>
            {!!titleH5 && (
                <h5
                    className={'h5-title'}
                    data-animated-group="animated-content"
                    style={{
                        transition: !windowSize ? `all .75s ease .1s` : ``,
                        opacity: startAnimation ? 1 : 0
                    }}
                >
                    <span className={'text'}>{titleH5}</span>
                </h5>
            )}


            {!!titleH2 && (
                <h2
                    className={'h2-title'}
                    style={{overflow: 'hidden'}}
                >
                    <div className={'text'}
                         data-animated-group="animated-title"
                         style={{
                             transform: `translateY(${startAnimation ? '0' : '100%'})`,
                             transition: !windowSize ? `all .75s ease .1s` : ``
                         }}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(titleH2, sanitizeOpt.title)}}/>
                        {!!link && (
                            <AniLink to={normalizePath(link)}
                                     paintDrip
                                     duration={1}
                                     hex="#ed5d2b"
                                     className={'mc-button-secondary link-all-view'}>
                            </AniLink>
                        )}
                </h2>
            )}
        </>
    )
}

export default TitleBlock