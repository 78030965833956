import React, {useEffect, useState} from "react";
import {Item} from './Item';
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";
import TitleBlock from "../title-lists/TitleBlock";

const EventsCalendarLists = ({isVisible, data, showButton, showNumberItems}) => {
    const [open, setOpen] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(()=>{
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if(setIsVisible) {
            window.setTimeout(() => {
                setStartAnimation(true);
            }, 100)
        }
    }, [isVisible]);

    const setVisible = (showNumberItems, i) => (
        {
            display: !!showNumberItems ?
                ((i < showNumberItems) || (open)) ? 'block' : 'none' :
                'block'
        }
    );

    const groupBy = data.nodes.reduce((r, a) => {
        const fullDateStart = new Date(a.calendarFields.dateStart).getTime(),
            fullDateEnd = a.calendarFields.dateEnd ? new Date(a.calendarFields.dateEnd).getTime() : false,
            today = new Date(),
            monthYear =  new Date(a.calendarFields.dateStart).toLocaleString('en-US', {month: 'long', year: 'numeric'}),
            yesterday = today.setDate(today.getDate() - 1);

            // if (fullDateEnd) {
            //     r[monthYear] = fullDateEnd >= yesterday ? [...r[monthYear] || [], a] : r[monthYear];
            // } else {
            //     r[monthYear] = fullDateStart >= yesterday ? [...r[monthYear] || [], a] : r[monthYear];
            // }
            
            r[monthYear] = [...r[monthYear] || [], a];

        return r;
    }, []);

    return (
        <>
            <div className={'case-study-list events-calendar-list flex-col'}>
                {Object.entries(groupBy).map((key, i) => (
                    <React.Fragment key={i}>
                        {!!key[1].length && (
                            <TitleBlock
                                key={i}
                                titleH2={key[0]}
                                titleH5={'Online Conferences'}
                                isVisible={startAnimation}
                            />
                        )}
                        {!!data?.nodes && key[1].map((page, i)  => (
                            page && <Item
                                key={i}
                                setVisible={setVisible}
                                showNumberItems={showNumberItems}
                                i={i}
                                page={page}
                                startAnimation={startAnimation}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </div>
            {!!showButton && (
                <button onClick={handleOpen}
                        className={'transition-link paragraph-primary__bold possibilities-link mc-link mc-button-secondary right'}>
                    View all cases
                </button>
            )}
        </>
    )
}

export default EventsCalendarLists;

